<template>
  <v-card>
    <div class="d-flex align-center justify-space-between">
      <div>
        <v-card-title class="text-no-wrap">
          Réservation
        </v-card-title>
        <v-card-subtitle>Détails sur la réservation</v-card-subtitle>
      </div>

      <v-avatar
        color="primary"
        size="48"
        class="v-avatar-light-bg primary--text me-5"
      >
        <v-icon
          size="30"
          color="primary"
        >
          {{ icons.mdiCash }}
        </v-icon>
      </v-avatar>
    </div>

    <v-card-text>
      <v-list>
        <v-list-item
          v-show="$store.state.recovery.recovery.payment_id"
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCreditCard }}
            </v-icon>
            Ordre:
          </span>
          <span
            class="text--secondary font-weight-bold"
            @click="openDetails($store.state.recovery.recovery.payment_id)"
          >
            <v-chip pill>
              <v-avatar
                :color=" $store.state.recovery.recovery.payment_id ? '' : 'primary'"
                :class=" $store.state.recovery.recovery.payment_id ? '' : 'v-avatar-light-bg primary--text'"
                size="32"
                left
              >
                <span>{{ $store.state.recovery.recovery.payment_id !== null && $store.state.recovery.recovery.payment_id !== undefined ? $store.state.recovery.recovery.payment.order.slice(0,2).toUpperCase() : null }}</span>
              </v-avatar>
              {{ $store.state.recovery.recovery.payment ? $store.state.recovery.recovery.payment.order : null }}
            </v-chip>
          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiAccountCash }}
            </v-icon>
            Compte Payeur:
          </span>
          <span class="text--secondary font-weight-bold">
            {{ $store.state.recovery.recovery.payment ? $store.state.recovery.recovery.payment.svc_number : null }}
          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiAccountCash }}
            </v-icon>
            Motif:
          </span>
          <span class="text--secondary font-weight-bold">
            {{ $store.state.recovery.recovery.payment ? $store.state.recovery.recovery.payment.pay_reason.label : null }}
          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCash }}
            </v-icon>
            Montant :
          </span>
          <span class="text--secondary font-weight-bold">
            <v-chip
              v-show="$store.state.recovery.recovery.payment !== null"
              color="primary"
              small
            >
              {{ $store.state.recovery.recovery.payment ? formatAmount($store.state.recovery.recovery.payment.base_amount) : null }}
            </v-chip>
          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCash }}
            </v-icon>
            Montant hors frais:
          </span>
          <span class="text--secondary font-weight-bold">
            <v-chip
              v-show="$store.state.recovery.recovery.payment !== null"
              color="secondary"
              small
            >
              {{ $store.state.recovery.recovery.payment ? formatAmount($store.state.recovery.recovery.payment.amount) : null }}
            </v-chip>
          </span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCash }}
            </v-icon>
            Frais:
          </span>
          <span class="text--secondary font-weight-bold">
            <v-chip
              v-show="$store.state.recovery.recovery.payment !== null"
              color="secondary"
              small
            >
              {{ $store.state.recovery.recovery.payment ? formatAmount($store.state.recovery.recovery.payment.fee_amount) : null }}
            </v-chip>
          </span>
        </v-list-item>
        <v-list-item
          v-show="$store.state.recovery.recovery.payment && $store.state.recovery.recovery.payment.validated_at !== null"
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiAccount }}
            </v-icon>
            Validé le:
          </span>
          <span class="text--secondary font-weight-bold">{{ $store.state.recovery.recovery.payment ? formattingDate($store.state.recovery.recovery.payment.validated_at) : null }}</span>
        </v-list-item>
        <v-list-item
          v-show="$store.state.recovery.recovery.payment && $store.state.recovery.recovery.payment.cancelled_at !== null"
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiAccount }}
            </v-icon>
            Annulé le:
          </span>
          <span class="text--secondary font-weight-bold">{{ $store.state.recovery.recovery.payment ? formattingDate($store.state.recovery.recovery.payment.cancelled_at) : null }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCalendar }}
            </v-icon>
            Date de création:
          </span>
          <span class="text--secondary font-weight-bold">{{ $store.state.recovery.recovery.payment ? formattingDate($store.state.recovery.recovery.payment.created_at) : null }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCalendarEdit }}
            </v-icon>
            Date de mise à jour:
          </span>
          <span class="text--secondary font-weight-bold">{{ $store.state.recovery.recovery.payment ? formattingDate($store.state.recovery.recovery.payment.updated_at) : null }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCalendarMinus }}
            </v-icon>
            Date de suppression:
          </span>
          <span class="text--secondary font-weight-bold">{{ $store.state.recovery.recovery.payment && $store.state.recovery.recovery.payment.deleted_at ? formattingDate($store.state.recovery.recovery.payment.deleted_at) : null }}</span>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAccount,
  mdiBadgeAccountAlert,
  mdiBusArticulatedEnd,
  mdiBusArticulatedFront, mdiCalendar, mdiCalendarEdit, mdiCalendarMinus,
  mdiCash, mdiCashMarker, mdiCashMultiple, mdiCheck,
  mdiClockStart,
  mdiImageText, mdiMapMarkerDown, mdiMapMarkerUp,
  mdiRoad, mdiSeatPassenger, mdiAccountCash, mdiCreditCard,
} from '@mdi/js'
import { formatDate } from '@core/date/dateFunctions'
import router from '@/router'
import {intToCurrency} from "@core/dynamicComponents/dynamicComponents";
import store from "@/store";

export default {
  name: 'RecoveryPaymentCard',

  setup() {
    const openDetails = id => {
      router.push({ name: 'showpayment', params: { id } })
    }

    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    const formattingDate = date => formatDate(date)

    return {
      formatAmount,
      openDetails,
      formattingDate,
      icons: {
        mdiRoad,
        mdiCreditCard,
        mdiImageText,
        mdiCash,
        mdiClockStart,
        mdiBusArticulatedFront,
        mdiBusArticulatedEnd,
        mdiSeatPassenger,
        mdiCashMarker,
        mdiCalendar,
        mdiCalendarMinus,
        mdiCalendarEdit,
        mdiMapMarkerDown,
        mdiMapMarkerUp,
        mdiBadgeAccountAlert,
        mdiCashMultiple,
        mdiCheck,
        mdiAccount,
        mdiAccountCash,
      },
    }
  },
}
</script>

<style scoped>

</style>
