<template>
  <div>
    <custom-toolbar
      :title="'Détails du recouvrement'"
      @refresh="refreshObjet"
    />
    <v-row class="mt-1" >
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <v-skeleton-loader
          v-show="isDialogVisible"
          boilerplate
          class="mx-auto"
          elevation="2"
          max-width="600"
          type="card-avatar, article, article, actions"
        ></v-skeleton-loader>
        <recovery-panel v-show="!isDialogVisible"/>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <v-skeleton-loader
          v-show="isDialogVisible"
          boilerplate
          class="mx-auto"
          elevation="2"
          max-width="600"
          type="card-avatar, article, article, actions"
        ></v-skeleton-loader>
        <recovery-payment v-show="!isDialogVisible"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, ref } from '@vue/composition-api'
import {
  mdiArrowLeftBoldCircleOutline,
  mdiCashMultiple,
  mdiMapMarkerDistance,
  mdiRefresh,
} from '@mdi/js'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import RecoveryPanel from '@core/components/recovery/RecoveryPanel'
import RecoveryStatusList from '@core/components/recovery/RecoveryStatusList'
import RecoveryPayment from '@core/components/recovery/RecoveryPayment'
import store from '@/store'
import router from '@/router'
import CustomToolbar from "@core/components/toolbar/CustomToolbar";
import {watch} from "@vue/composition-api/dist/vue-composition-api";

export default {
  name: 'RecoveryDetails',
  components: {
    CustomToolbar,
    RecoveryPayment, RecoveryStatusList, RecoveryPanel, LoaderDialog,
  },
  setup() {
    const isDialogVisible = ref(false)
    const userTab = ref(null)
    // eslint-disable-next-line no-shadow,no-use-before-define
    const route = getCurrentInstance().proxy.$route

    const getUser = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        if (store.state.recovery.recovery === null || store.state.recovery.recovery.id !== id) {
          isDialogVisible.value = true
          store.dispatch('recovery/getRecoveryById', id).then(() => {
            isDialogVisible.value = false
          }).catch(error => {
            console.log(error)
            router.back()
          })
        }
      }
    }

    const refreshObjet = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        isDialogVisible.value = true
        store.dispatch('recovery/getRecoveryById', store.state.recovery.recovery.id).then(() => {
          isDialogVisible.value = false
        }).catch(error => {
          console.log(error)
        })
      }
    }

    onMounted(getUser)

    watch(() => store.state.user.lang, lang => {
      refreshObjet()
    })

    watch(() => store.state.recovery.refreshItemCount, () => {
      isDialogVisible.value = true
      store.dispatch('recovery/getRecoveryById', store.state.recovery.refreshItemId).then(() => {
        isDialogVisible.value = false
      }).catch(error => {
        console.log(error)
      })
    })

    const prev = () => {
      router.back()
    }

    const tabs = [
      { icon: mdiMapMarkerDistance, title: 'Arrêts' },
      { icon: mdiCashMultiple, title: 'Paiements' },
    ]

    return {
      isDialogVisible,
      getUser,
      prev,
      refreshObjet,
      tabs,
      userTab,
      icons: {
        mdiRefresh,
        mdiArrowLeftBoldCircleOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
